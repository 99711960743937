import React from 'react'
// Types
import { SectionBaseProps, SectionPodcast } from '../../../types/sections'
// Components
import { Grid, Row, Col } from 'react-styled-flexboxgrid'
import { Flex, Box, Button, Heading, Text } from '../../../atoms'

export interface PodcastProps extends SectionBaseProps {
  data: SectionPodcast
}

const Podcast: React.FC<PodcastProps> = ({ data }) => {
  const { id, sectionId, headline, content, button, iframeUrl, playerPosition } = data

  return (
    <Box
      as={'section'}
      id={sectionId || id}
      className={'p-section'}
      my={[80, 80, 120]}
      py={playerPosition !== 'bottom' ? [32, 48, 64] : undefined}
    >
      <Grid>
        <Row center={'xs'} middle={'xs'} reverse={playerPosition === 'left'}>
          <Col xs={12} sm={10} md={playerPosition === 'bottom' ? 8 : 6} lg={playerPosition === 'bottom' ? 7 : 6}>
            <Box
              pr={playerPosition === 'right' ? [0, 0, 10] : undefined}
              pl={playerPosition === 'left' ? [0, 0, 10] : undefined}
            >
              {!!headline && (
                <Heading as={'h4'} textAlign={playerPosition === 'bottom' ? 'center' : 'left'}>
                  {headline}
                </Heading>
              )}
              {!!content && (
                <Box mt={5}>
                  <Text textAlign={playerPosition === 'bottom' ? 'center' : 'left'} whiteSpace={'pre-line'}>
                    {content}
                  </Text>
                </Box>
              )}
              {!!button && (
                <Flex mt={6} justifyContent={playerPosition === 'bottom' ? 'center' : 'flex-start'}>
                  <Box flex={['0 0 100%', '0 0 auto']}>
                    <Button fluid {...button} />
                  </Box>
                </Flex>
              )}
            </Box>
          </Col>
          <Col xs={12} sm={10} md={playerPosition === 'bottom' ? 8 : 6} lg={playerPosition === 'bottom' ? 7 : 6}>
            {!!iframeUrl && (
              <Box mt={playerPosition === 'bottom' ? [6, 8, 10] : [6, 8, 0]}>
                <iframe width={'100%'} frameBorder={'no'} scrolling={'no'} seamless src={iframeUrl} />
              </Box>
            )}
          </Col>
        </Row>
      </Grid>
    </Box>
  )
}

export default Podcast
